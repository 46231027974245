<template>
  <b-container fluid class="bg full-height">
    <b-row class="full-height">
      <b-col cols="12" md="6" class="left-side px-3 d-flex justify-content-center align-item-center">
        <div class="inner-wrapper" data-anima="bottom">
          <span class="logo mb-5">
            <img v-if="!isHeaven" src="@/assets/logo.png" />
            <img v-else src="@/assets/img/icons-heaven/logo.svg" />
          </span>

          <div class="voltar-login">
            <router-link to="/">
              <img src="@/assets/img/icons/arrow-page.svg" />
              {{ $t("views.seller.password_recovery.text_1470") }}
            </router-link>
          </div>

          <div class="info-recuperacao register-title" v-if="!token">
            <h5 ref="titulo_recupera">
              {{ $t("views.seller.password_recovery.text_1255") }}
            </h5>
            <h6>
              {{ $t("views.seller.password_recovery.text_1270") }}
            </h6>
            <p ref="texto_recupera">
              {{ $t("views.seller.password_recovery.text_1256") }}
            </p>
          </div>

          <div class="info-recuperacao register-title" v-if="token">
            <h5>{{ $t("views.seller.password_recovery.text_1257") }}</h5>
            <p>
              {{ $t("views.seller.password_recovery.text_1258") }}
            </p>
          </div>

          <b-form novalidate v-if="!sent" @submit.prevent="onSubmit">
            <b-form-group
              v-if="!token"
              :label="$t('views.seller.password_recovery.text_1471')"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="email"
                name="email"
                type="email"
                :placeholder="$t('views.seller.password_recovery.text_1472')"
                :class="{'heaven': isHeaven}"
                v-validate="'required|email'"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('email') : null"
              >
                {{ $t("views.seller.password_recovery.text_1259") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              v-if="token"
              :label="$t('views.seller.password_recovery.text_1473')"
              label-for="password"
            >
              <b-input-group>
                <b-form-input
                  id="password"
                  placeholder="••••••••"
                  v-model="password"
                  type="password"
                  name="password"
                  v-validate="'strong_password'"
                  ref="password"
                  :class="{'heaven': isHeaven}"
                ></b-form-input>
                <b-input-group-append v-if="password">
                  <b-button
                    class="px-2"
                    ref="mostrar_senha"
                    @click="mostrarSenha"
                    variant="light"
                    >{{
                      $t("views.seller.password_recovery.text_1474")
                    }}</b-button
                  >
                </b-input-group-append>
              </b-input-group>
              <b-form-text>
                <password-validation
                  :password="password"
                ></password-validation>
              </b-form-text>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('password') : null"
              >
                {{ $t("views.seller.password_recovery.text_1260") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              v-if="token"
              :label="$t('views.seller.password_recovery.text_1475')"
              label-for="confirm_password"
            >
              <b-form-input
                id="confirm_password"
                placeholder="••••••••"
                v-model="confirm_password"
                type="password"
                name="confirm_password"
                v-validate="'required|min:8|confirmed:password'"
                :class="{'heaven': isHeaven}"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('confirm_password') : null"
              >
                {{ $t("views.seller.password_recovery.text_1261") }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-row class="mt-4">
              <b-overlay
                :show="loading"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="dark"
                class="d-inline-block col-12"
              >
                <BaseButton
                  variant="primary"
                  v-if="!token"
                  ref="button"
                  :disabled="loading"
                  type="submit"
                  class="btn-submit"
                  :class="{'heaven': isHeaven}"
                  @click="onSubmit"
                >
                  {{ $t("views.seller.password_recovery.text_1262") }}
                </BaseButton>

                <Captcha
                  ref="Captcha"
                  v-if="token"
                  @changeVerify="changeVerify"
                >
                  <BaseButton
                    variant="info2"
                    v-if="token"
                    ref="button"
                    :disabled="loading"
                    type="submit"
                    class="btn-submit"
                    @click="onSubmit"
                  >
                    {{ $t("views.seller.password_recovery.text_1263") }}
                  </BaseButton>
                </Captcha>
              </b-overlay>
            </b-row>
          </b-form>

          <BaseButton
            variant="info2"
            v-if="sent"
            class="btn-submit mt-5"
            @click="returnLogin"
          >
            {{ $t("views.seller.password_recovery.text_1264") }}
          </BaseButton>
        </div>
      </b-col>

      <b-col cols="12" md="6" class="right-side" :class="{'heaven': isHeaven}"> </b-col>
    </b-row>
  </b-container>
</template>
<script>
import PasswordRecoveryService from "@/services/resources/PasswordRecoveryService";

const service = PasswordRecoveryService.build();

import Captcha from "@/components/security/Captcha.vue";
import PasswordValidation from "@/components/shared/PasswordValidation.vue";

export default {
  name: "Login",
  components: {
    Captcha,
    PasswordValidation,
  },
  data() {
    return {
      submit: false,
      email: "",
      password: "",
      confirm_password: "",
      success: true,
      loading: false,
      token: null,
      sent: false,
      captcha: "",
    };
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    changeVerify(e) {
      this.captcha = e;
      this.onSubmit("get");
    },
    returnLogin() {
      this.$router.push("/");
    },
    mostrarSenha() {
      const input = document.querySelector("#password");
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        input.setAttribute("type", "text");
        this.$refs.mostrar_senha.innerText = this.$t(
          "views.seller.password_recovery.text_1476"
        );
      } else {
        input.setAttribute("type", "password");
        this.$refs.mostrar_senha.innerText = this.$t(
          "views.seller.password_recovery.text_1474"
        );
      }
    },

    onSubmit(get) {
      if (get !== "get" && this.token) {
        this.$refs.Captcha.setCaptcha();
        return;
      }
      this.submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true;

          if (this.token) {
            this.changePassword();
          } else {
            this.requestToken();
          }
        }
      });
    },
    requestToken() {
      let data = {
        email: this.email,
        type: "REQUEST_TOKEN",
      };

      service
        .create(data)
        .then(() => {
          this.$bvToast.toast(
            this.$t("views.seller.password_recovery.text_1265"),
            {
              title: this.$t("views.seller.password_recovery.text_1266"),
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );

          this.sent = true;
          this.$refs.titulo_recupera.innerText = `✔️ ${this.$t(
            "views.seller.password_recovery.text_1267"
          )}`;
          this.$refs.texto_recupera.classList.add("sent");
          this.$refs.texto_recupera.innerHTML = `${this.$t(
            "views.seller.password_recovery.text_1268"
          )} <b>${this.email}</b>`;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changePassword() {
      let data = {
        password: this.password,
        token: this.token,
        captcha: this.captcha,
        type: "CHANGE_PASSWORD",
      };

      service
        .create(data)
        .then(() => {
          this.$bvToast.toast(
            this.$t("views.seller.password_recovery.text_1269"),
            {
              title: this.$t("views.seller.password_recovery.text_1266"),
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    if (typeof this.$route.params.token != "undefined") {
      this.token = this.$route.params.token;
    }
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background: var(--primary-50);
}

.right-side {
  background-image: url("~@/assets/img/cadastro-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 88888;
  width: 100%;
  height: 100vh;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.right-side.heaven {
  background: radial-gradient(circle, var(--blue-50)32%, var(--blue-500) 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 88888;
  width: 100%;
  height: 100vh;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.right-side.heaven::after {
  content: url("~@/assets/img/icons-heaven/logo.svg");
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.left-side {
  background: var(--gray-white);
  padding: 0 !important;
  height: 100%;

  .inner-wrapper {
    margin-top: 40px;
    padding: 1% 18%;

    .logo {
      img {
        width: 120px;
        height: auto;
        margin-bottom: 40px;
      }
    }
  }
}

.voltar-login {
  padding-bottom: 30px;
  border-bottom: 1px solid var(--gray-50);
  margin-bottom: var(--spacing-12);
}

.voltar-login a {
  display: flex;
  align-items: center;
  color: var(--old-800);
  font-size: 15px;
}

.voltar-login img {
  transform: rotate(180deg);
  margin-right: 20px;
  margin-top: -2px;
}

.info-recuperacao h5 {
  font-size: 16px;
  font-weight: 600;
}

.info-recuperacao p {
  color: var(--gray-200);
  font-size: 14px;
  line-height: 1.5;
  margin: 20px 0 30px 0;
}

.info-recuperacao{
  margin: var(--spacing-12) var(--spacing-0) var(--spacing-10) var(--spacing-0);
  display: grid;
  gap: var(--spacing-6);
}

.info-recuperacao p.sent {
  font-size: 15px;
}

.btn-submit {
  width: 100%;
}

.form-group {
  position: relative;
}

.form-group + .form-group {
  margin-top: 20px;
}

.mostrar-senha {
  font-size: 13px;
  position: absolute;
  top: 55%;
  right: 20px;
  cursor: pointer;
  color: var(--gray-200);
}
@media screen and (max-width: 768px) {
  .logo img {
    width: 80px !important;
    margin-bottom: 30px !important;
  }
  .btn-submit {
    margin-bottom: 40px;
  }
}

.form-control.heaven:hover {
  border-color: var(--blue-500) !important;
}
</style>
